function GreetingMessages () {
  if (window.location.pathname.includes('greeting_messages')) {
    $('.time').datetimepicker({
      format: 'HH:mm',
      useCurrent: false
    })
    $('.start').on('dp.change', function (e) {
      $('.end').data('DateTimePicker').minDate(e.date)
    })

    $('.end').on('dp.change', function (e) {
      $('.start').data('DateTimePicker').maxDate(e.date)
    })
  }
}

export default GreetingMessages
